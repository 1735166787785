// require("trix")
require("@rails/actiontext")

var Trix = require("trix");

/* what the newly created button does */
Trix.config.textAttributes.orange = {
    style: { color: "rgb(215, 99, 21)" },
    parser: function(element) {
        return element.style.color === "rgb(215, 99, 21)"
    },
    inheritable: true
}

Trix.config.textAttributes.green = {
    style: { color: "rgb(0, 129, 143)" },
    parser: function(element) {
        return element.style.color === "rgb(0, 129, 143)"
    },
    inheritable: true
}

Trix.config.textAttributes.blue = {
    style: { color: "blue" },
    parser: function(element) {
        return element.style.color === "blue"
    },
    inheritable: true
}

Trix.config.textAttributes.yellow = {
    style: { color: "rgb(246, 194, 68)" },
    parser: function(element) {
        return element.style.color === "rgb(246, 194, 68)"
    },
    inheritable: true
}

Trix.config.textAttributes.mark = {
    tagName: "mark",
    breakOnReturn: false,
    inheritable: true
}

Trix.config.blockAttributes.heading = {
    tagName: "h2",
    terminal: true,
    breakOnReturn: true,
    group: false
}

Trix.config.blockAttributes.subHeading = {
    tagName: "h3",
    terminal: true,
    breakOnReturn: true,
    group: false
}

Trix.config.blockAttributes.custom_trix_flex = {
  tagName: 'custom_trix_flex',
  parse: false,
};

Trix.config.blockAttributes.custom_trix_element = {
  tagName: 'custom_trix_element',
  listAttribute: 'custom_trix_flex',
  group: false,
  nestable: true,
  test: function (element) {
      return Trix.tagName(element.parentNode) === Trix.config.blockAttributes[this.listAttribute].tagName
  }
};

Trix.config.blockAttributes.anchor = {
    tagName: "anchor",
    group: false,
    breakOnReturn: true,
}
Trix.config.blockAttributes.customHtml = {
    tagName: "custom",
    terminal: true,
    text: {
      plaintext: true
    }
}

/* insert the button visual in the default toolbar */
addEventListener("trix-initialize", function(event) {
    var h2ButtonHTML = '<button type="button" class="trix-button" data-trix-attribute="heading" title="heading">H2</button>'
    var h3ButtonHTML = '<button type="button" class="trix-button" data-trix-attribute="subHeading" title="subHeading">H3</button>'
    var markButtonHTML = '<button type="button" class="trix-button" data-trix-attribute="mark" title="mark"><mark>MARK</mark></button>'
    var orangeButtonHTML = '<button type="button" class="trix-button" data-trix-attribute="orange" title="orange" style="color: rgb(215, 99, 21);">O</button>'
    var greenButtonHTML = '<button type="button" class="trix-button" data-trix-attribute="green" title="green" style="color: rgb(0, 129, 143);">G</button>'
    var blueButtonHTML = '<button type="button" class="trix-button" data-trix-attribute="blue" title="blue" style="color: blue;">B</button>'
    var yellowButtonHTML = '<button type="button" class="trix-button" data-trix-attribute="yellow" title="yellow" style="color: rgb(246, 194, 68);">Y</button>'
    var anchorButtonHTML = '<button type="button" class="trix-button" data-trix-attribute="anchor" title="anchor">⚓︎</button>'
    var customButtonHTML = '<button type="button" class="trix-button" data-trix-attribute="customHtml" title="custom">︎⚙</button>'

    event.target.toolbarElement.querySelector(".trix-button-group").insertAdjacentHTML("beforeend", h2ButtonHTML);
    event.target.toolbarElement.querySelector(".trix-button-group").insertAdjacentHTML("beforeend", h3ButtonHTML);
    event.target.toolbarElement.querySelector(".trix-button-group").insertAdjacentHTML("beforeend", markButtonHTML);
    event.target.toolbarElement.querySelector(".trix-button-group").insertAdjacentHTML("beforeend", orangeButtonHTML);
    event.target.toolbarElement.querySelector(".trix-button-group").insertAdjacentHTML("beforeend", greenButtonHTML);
    event.target.toolbarElement.querySelector(".trix-button-group").insertAdjacentHTML("beforeend", blueButtonHTML);
    event.target.toolbarElement.querySelector(".trix-button-group").insertAdjacentHTML("beforeend", yellowButtonHTML)
    event.target.toolbarElement.querySelector(".trix-button-group.trix-button-group--block-tools").insertAdjacentHTML("beforeend", anchorButtonHTML)
    event.target.toolbarElement.querySelector(".trix-button-group.trix-button-group--block-tools").insertAdjacentHTML("beforeend", customButtonHTML)

    const input = event.target.toolbarElement.querySelector("input[name=href]");
    input.type = "text";
})
